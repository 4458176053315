<template>
    <div>
        <v-card flat>
          <div>
            <v-container class="white lighten-5 fill-height">
                <v-row class="mb-6 pl-5" no-gutters>
                <v-col :md="3">
                  <v-card @click="createDeals">
                    <v-img
                      src="@/assets/ecard-bg.png"
                      class="white--text align-end"
                      height="200px">
                      <div class="ml-4" style="position: relative; top: -50px">
                        <v-row>
                          <v-col :md="5">
                            <v-avatar size="88" tile>
                              <v-img src="@/assets/cardcard.png"></v-img>
                                <!-- <v-icon >$vuetify.icons.ecard</v-icon> -->
                            </v-avatar>
                          </v-col>
                          <v-col :md="6" class="pt-6">
                            <span class="title mr-2" style="text-align: left; color: #00D79E;">{{ lang.t('$vuetify.create_newecard') }}</span>
                          </v-col>
                        </v-row>
                        </div>
                    </v-img>
                  </v-card>
                </v-col>
                <v-col :md="4" class="ml-6">
                  <v-card class="pt-6" width="400px" height="200px" :raised="true">
                      <v-row class="mb-4 align-center" no-gutters>
                          <v-col :md="4" class="text-center d-flex-align-center">
                              <v-avatar size="68" color="#ebfcf7">
                                <v-icon size="28">$vuetify.icons.store_green</v-icon>
                              </v-avatar>
                          </v-col>
                          <v-col >
                              <span class="headline font-weight-bold">121</span><br>
                              <span class="body-2" style="color: #BEC2C4">{{ lang.t('$vuetify.merchant_with_ecard') }}</span>
                          </v-col>
                      </v-row>
                      <v-row class="align-center pl-5" no-gutters>
                          <v-col :md="3" class="ma-2 text-center d-flex-align-center">
                              <div class="title text-center">766</div>
                              <div class="caption" style="color: #BEC2C4;">{{ lang.t('$vuetify.new_voucher') }}</div>
                          </v-col>
                          <v-col :md="3" class="ma-2 text-center d-flex-align-center">
                              <div class="title text-center">21</div>
                              <div class="caption" style="color: #BEC2C4;">{{ lang.t('$vuetify.service_voucher') }}</div>
                          </v-col>
                          <v-col :md="3" class="ma-2 text-center d-flex-align-center">
                              <div class="title text-center">766</div>
                              <div class="caption" style="color: #BEC2C4;">{{ lang.t('$vuetify.class_voucher') }}</div>
                          </v-col>
                      </v-row>
                  </v-card>
                </v-col>
                <v-col :md="4" class="ml-6">
                  <v-card class="pt-6" width="400px" height="200px" :raised="true">
                      <v-row class="mb-4 align-center" no-gutters>
                          <v-col :md="4" class="text-center d-flex-align-center">
                              <v-avatar size="68">
                                <v-icon size="68">$vuetify.icons.hand_with_card</v-icon>
                              </v-avatar>
                          </v-col>
                          <v-col >
                              <span class="headline font-weight-bold">21</span><br>
                              <span class="body-2" style="color: #BEC2C4">{{ lang.t('$vuetify.ecard_used') }}</span>
                          </v-col>
                      </v-row>
                      <v-row class="align-center pl-5" no-gutters>
                          <v-col :md="5" class="ma-2 text-center d-flex-align-center">
                              <div class="title text-center">11</div>
                              <div class="caption" style="color: #BEC2C4;">{{ lang.t('$vuetify.service_voucher') }}</div>
                          </v-col>
                          <v-col :md="5" class="ma-2 text-center d-flex-align-center">
                              <div class="title text-center">12</div>
                              <div class="caption" style="color: #BEC2C4;">{{ lang.t('$vuetify.class_voucher') }}</div>
                          </v-col>
                      </v-row>
                  </v-card>
                </v-col>
                </v-row>
                <v-card class="pa-2" width="100%">
                    <v-card-title>
                      <span style="color: #6A6A6A">12456 {{ lang.t('$vuetify.voucher') }}</span>
                      <v-spacer></v-spacer>
                      <v-btn rounded outlined class="text-capitalize" style="margin-right: 8px"><v-icon left>$vuetify.icons.download</v-icon>{{ lang.t('$vuetify.download') }}</v-btn>
                      <v-btn rounded outlined class="text-capitalize" style="margin-right: 8px"><v-icon left>$vuetify.icons.print</v-icon>{{ lang.t('$vuetify.print') }}</v-btn>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        style="width: 10px;"
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table
                      :headers="headers"
                      :items="dataEcard"
                      :search="search"
                      hide-default-footer
                      class="mytable"
                      style="border-bottom: none"
                      @click:row="rowClick"
                    >
                    <template v-slot:header.service_name="{ header }">
                      <span style="color: #00B082">{{ header.text }}</span>
                    </template>
                    <template v-slot:item.revenue="{ item }">
                      <span style="color: #4CD137">{{ item.revenue}}</span>
                    </template>
                    <template v-slot:item.cancelled="{ item }">
                      <span style="color: #E84118">{{ item.cancelled}}</span>
                    </template>
                    <template v-slot:item.action="{ item }">
                      <v-btn icon>
                        <v-icon color="#00D79E" class="mr-2" @click="editItem(item)">mdi-file-edit-outline</v-icon>
                      </v-btn>
                      <button-confirm :name="item.title" :table="true" :message="'$vuetify.del_ecard'" @confirm="delDataEcard(item.id)"></button-confirm>
                    </template>
                    </v-data-table>

                    <v-spacer></v-spacer><br>
                    <v-pagination
                      v-model="page"
                      color="#00B082"
                      :length="total_page"
                      :total-visible="7"
                      circle
                      ></v-pagination>
                </v-card>
            </v-container>
            </div>
            <v-dialog
              v-model="dialog"
              :max-width="dialogWidth">
              <v-card class="pb-5">
                <v-toolbar color="elevation-0">
                  <v-toolbar-title >
                    <span class="ml-4 font-weight-bold">{{ lang.t('$vuetify.create_newecard') }}</span><br>
                    <div v-if="step === 1" class="body-2 ml-4" style="color: #BEC2C4">{{ lang.t('$vuetify.step_1deals') }}</div>
                    <div v-if="step === 2" class="body-2 ml-4" style="color: #BEC2C4">{{ lang.t('$vuetify.step_2ecard') }}</div>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn icon @click="dialog = false, dialogWidth = '664'">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <div class="mx-6 mt-6">
                  <div v-if="step === 1" class="ml-2">
                    <span class="body-1" style="color: #BEC2C4">{{ lang.t('$vuetify.merchant_name') }}</span>
                    <v-row>
                      <v-col :md="8">
                        <v-text-field
                          v-model="search"
                          :label="lang.t('$vuetify.search') + ' ' + lang.t('$vuetify.service')"
                          color="#BEF4E6"
                          dense
                          single-line
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-autocomplete
                          v-model="selectCity"
                          :items="dropdown_font"
                          dense
                          solo
                          filled
                          label="All City"
                          background-color="#F5F5F5"
                          ></v-autocomplete>
                      </v-col>
                    </v-row>
                      <v-row>
                        <v-col :md="6" v-for="i in 12" :key="i">
                          <v-card @click="goStepTwo">
                            <div class="d-flex flex-no-wrap justify-space-between">
                              <v-card style="border-bottom-right-radius: 100px; width: 56px" @click="changeStep">
                                <v-img
                                  src="@/assets/BRERC_SPA_Cabin_1A.png"
                                  class="white--text align-end"
                                  height="100%"/>
                              </v-card>
                              <div>
                                <v-card-title class="subtitle-1">
                                  Title adadsada dadsasdsa
                                </v-card-title>
                              </div>
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="step === 2" class="ml-2">
                    <v-row>
                      <v-col :md="4">
                        <div>
                            <v-btn v-for="(item, idx) in cardSem" :key="item" class="mt-3" rounded width="240" color="#00D79E" height="56" style="color: #ffffff" @click="changeCard(idx)">{{ item.name }}</v-btn>
                        </div>
                        <div class="mt-4" @click="dupCard">
                            <v-btn small fab dark height="24" width="24" color="#00A8FF" depressed @click="dupCard">
                                <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                            <span class="ml-2" style="color: #00A8FF">{{ lang.t('$vuetify.add') }} {{ lang.t('$vuetify.other_ecard') }}</span>
                        </div>
                      </v-col>
                      <v-col>
                        <v-card raised>
                            <v-card-title>
                              <v-row>
                                <v-col :md="3">
                                  {{ selectCard.name }}
                                </v-col>
                                <v-col class="text-right">
                                  <div v-if="selectCard.idx !== 0">
                                    <v-btn rounded small depressed style="background: #FDF1EF 0% 0% no-repeat padding-box;border: 1px solid #E74C3C;color: #E74C3C" @click="delCard">Delete this e-card</v-btn>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card-title>
                            <div>
                                <hr style="border-top: 1px dashed #E0E0E0">
                                <div class="ml-6">
                                    <v-row>
                                    <v-col :md="6" >
                                        <div class="justify-space-between">
                                            <div class="d-flex flex-no-wrap justify-space-between mb-3">
                                                <span style="color: #BEC2C4">{{ lang.t('$vuetify.amount') }}</span>
                                            </div>
                                            <v-autocomplete v-model="selectCity" :items="amountList" outlined dense ></v-autocomplete>
                                            <div class="d-flex flex-no-wrap justify-space-between" style="margin-top: -20px; margin-bottom: -25px">
                                              <span class="mt-4" style="color: #BEC2C4">{{ lang.t('$vuetify.discount') }}</span>
                                              <v-switch
                                                v-model="switch1"
                                              ></v-switch>
                                            </div>
                                            <v-radio-group v-model="coloum" row>
                                              <v-radio label="Percentage" value="1"></v-radio>
                                              <v-radio label="Amount" value="2"></v-radio>
                                            </v-radio-group>
                                            <div v-if="coloum === '1'">
                                              <v-text-field
                                                v-model="amount"
                                                suffix="%"
                                                outlined
                                                dense
                                                type="number"
                                              ></v-text-field>
                                            </div>
                                            <div v-else>
                                              <v-row class="mt-n3 mb-n3">
                                                <v-col :md="4">
                                                  <v-autocomplete
                                                    v-model="selectCity"
                                                    :items="['Rp', '$']"
                                                    outlined
                                                    dense
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col>
                                                  <v-text-field
                                                    v-model="amount"
                                                    outlined
                                                    dense
                                                    type="number"
                                                  ></v-text-field>
                                                </v-col>
                                              </v-row>
                                            </div>
                                            <div class="d-flex flex-no-wrap justify-space-between mb-3">
                                                <span style="color: #BEC2C4">{{ lang.t('$vuetify.ecard_background') }}</span>
                                            </div>
                                            <v-radio-group v-model="row" row>
                                              <div class="text-center mr-7">
                                                <div style="width: 64px; height: 64px;background: transparent linear-gradient(143deg, #FF0099 0%, #493240 100%) 0% 0% no-repeat padding-box;border-radius: 8px;"></div>
                                                <div class="float-right mt-2">
                                                  <v-radio value="radio-1"></v-radio>
                                                </div>
                                              </div>
                                              <div class="text-center mr-7">
                                                <div style="width: 64px; height: 64px;background: transparent linear-gradient(143deg, #FF6CAB 0%, #7366FF 100%) 0% 0% no-repeat padding-box;border-radius: 8px;"></div>
                                                <div class="float-right mt-2">
                                                  <v-radio value="radio-2"></v-radio>
                                                </div>
                                              </div>
                                              <div class="text-center">
                                                <div style="width: 64px; height: 64px;background: transparent linear-gradient(143deg, #EE0979 0%, #FF6A00 100%) 0% 0% no-repeat padding-box;border-radius: 8px;"></div>
                                                <div class="float-right mt-2">
                                                  <v-radio value="radio-3"></v-radio>
                                                </div>
                                              </div>
                                            </v-radio-group>
                                        </div>
                                    </v-col>
                                    <v-col :md="6" >
                                        <div class="justify-space-between mr-6">
                                            <div class="d-flex flex-no-wrap justify-space-between" >
                                                <span class="mt-1 mb-2" style="color: #BEC2C4">{{ lang.t('$vuetify.expiry_period') }}</span>
                                            </div>
                                            <v-autocomplete v-model="selectCity" :items="['1 Month', '2 Month', '3 Month']" outlined dense ></v-autocomplete>
                                            <div class="d-flex flex-no-wrap justify-space-between" style="margin-top: -20px;">
                                              <span class="mt-4" style="color: #BEC2C4">{{ lang.t('$vuetify.day_limit') }}</span>
                                              <v-switch
                                                v-model="switch1"
                                              ></v-switch>
                                            </div>
                                            <v-autocomplete v-model="selectCity" :items="['Every Day', '1 Week', '1 Month']" outlined dense ></v-autocomplete>
                                            <div class="mt-9">
                                              <span class="caption" style="color: #BEC2C4">{{ lang.t('$vuetify.preview') }} :</span>
                                              <div style="box-shadow: 0px 0px 12px #0000001F; border-radius: 12px;">
                                                <v-img
                                                  src="@/assets/ecard-bg.png"
                                                  class="white--text align-end"
                                                  height="115px">
                                                  <div class="ml-4">
                                                    <div>
                                                      <span class="caption mr-8" style="text-align: left; color: #00D79E;">Djemari Salon and Spa</span>
                                                    </div>
                                                    <div :md="6">
                                                      <span class="caption mr-8" style="text-align: left; color: #00D79E;">1.000.000</span>
                                                    </div>
                                                  </div>
                                                  <div class="text-right mr-4 mt-5">
                                                    <div >
                                                      <div style="font-size: 7px; color: #00D79E;">{{ lang.t('$vuetify.available_until') }}</div>
                                                      <div style="font-size: 9px; color: #00D79E;">08/21</div>
                                                    </div>
                                                  </div>
                                                </v-img>
                                              </div>
                                            </div>
                                        </div>
                                    </v-col>
                                    </v-row>
                                    </div>
                            </div>
                        </v-card>
                        <div class="text-center mt-6">
                            <v-btn width="552px" rounded color="#00D79E" style="color: #FFFFFF">{{ lang.t('$vuetify.create') }} e-Card</v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    </div>
                </div>
              </v-card>
            </v-dialog>
        </v-card>
        <v-snackbar v-model="notify.notifyUpdate" :timeout="notify.timeoutNotify" multi-line top right :color="notify.colorNotify" >
          <div>
            <div class="subtitle-2 font-weight-bold">Status code {{ notify.titleNotify }}</div>
            <div>{{ notify.textNotify }}</div>
          </div>
          <v-btn color="white" text @click="notify.notifyUpdate = false">
            {{ lang.t('$vuetify.close') }}
          </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'
import { listEcard, delEcard } from '@/api/deals/ecard'

export default {
  name: 'ListActivity',
  props: ['extend', 'page', 'total_page'],

  data () {
    return {
      merchantList: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        }
      ],
      headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.merchant_name'),
          align: 'left',
          value: 'com_name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.ecard_amount'),
          align: 'left',
          value: 'amount'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.expiry_date'),
          align: 'center',
          value: 'nearest_expired'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.action'),
          align: 'center',
          value: 'action'
        }
      ],
      desserts: [
        {
          company_name: 'Djemari Salon and Spa',
          amount: '100k, 500k, 1000k (3)',
          date: '23 Feb 2020'
        },
        {
          company_name: 'Florent Women Salon Specialist',
          amount: '100k, 500k, 1000k (3)',
          date: '23 Feb 2020'
        },
        {
          company_name: 'Florent Women Salon Specialis',
          amount: '100k, 500k, 1000k (3)',
          date: '23 Feb 2020'
        },
        {
          company_name: 'Kakiku Reflexologi',
          amount: '100k, 500k, 1000k (3)',
          date: '23 Feb 2020'
        }
      ],
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu1: false,
      menu2: false,
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      dropdown_service: ['Service', 'Class'],
      selectCity: '',
      tab2: null,
      itemsTab: [
        'All', 'Message Special', 'Nail Treatment', 'Djemari Special Treatment'
      ],
      dataLine: {
        columns: ['date', 'appointment', 'cancelled'],
        rows: [{ 'date': '10', 'appointment': 10, 'cancelled': 5, 'classes': 2 },
          { 'date': '11', 'appointment': 20, 'cancelled': 20, 'classes': 6 },
          { 'date': '12', 'appointment': 17, 'cancelled': 16, 'classes': 5 },
          { 'date': '13', 'appointment': 28, 'cancelled': 24, 'classes': 10 }
        ]
      },
      dataLine2: {
        columns: ['date', 'product_sold'],
        rows: [{ 'date': '10', 'product_sold': 10, 'cancelled': 5, 'classes': 2 },
          { 'date': '11', 'product_sold': 20, 'cancelled': 20, 'classes': 6 },
          { 'date': '12', 'product_sold': 17, 'cancelled': 16, 'classes': 5 },
          { 'date': '13', 'product_sold': 28, 'cancelled': 24, 'classes': 10 }
        ]
      },
      colorsLine: ['#00D79E', '#FFB677', '#C7A8FF'],
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#E74C3C'
          }, {
            offset: 1,
            color: '#FFFFFF'
          }])
        }
      },
      xaxis: {
        type: 'category',
        boundaryGap: false
      },
      search: '',
      dataRing: {
        columns: ['date', 'sales'],
        rows: [{ 'date': '10', 'sales': 20 },
          { 'date': '11', 'sales': 45 }
        ]
      },
      ringSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        offsetY: 110,
        radius: [60, 100],
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        }
      },
      colorsRing: ['#7CFDBE', '#00B082', '#A1FFD1', '#C5DBFF'],
      title: {
        text: 125,
        subtext: 'services and \n classes',
        textStyle: {
          color: '#333333',
          fontSize: '36',
          fontStyle: 'normal'
        },
        x: 'center',
        y: '80px'
      },
      dialog: false,
      step: 0,
      dialogWidth: '664',
      amountList: [ '100.000', '300.000', '500.000', '1.000.000' ],
      cardSem: [
        {
          name: 'e-Card 1',
          idx: 0
        }
      ],
      selectCard: {},
      coloum: '1',
      dataEcard: [],
      notify: {
        timeoutNotify: 6000,
        textNotify: '',
        titleNotify: '',
        notifyUpdate: false,
        colorNotify: ''
      }
    }
  },

  mounted () {
    this.selectCard = this.cardSem[0]
    this.getListCard()
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    },

    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    computedDateFormattedMomentjs1 () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date2[0] ? moment(this.date2[0]).format('D') : ''
      endDate = this.date2[1] ? moment(this.date2[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    activeFilter () {
      if (this.model == null) return undefined
      return this.filters[this.model].fn
    }
  },

  methods: {
    getListCard (page) {
      this.listLoading = true
      var param = {
        page: page,
        per_page: 10,
        sort_type: 'desc',
        sort_column: 'id'
      }
      listEcard(param).then((response) => {
        this.dataEcard = response.data.data
        this.total_blog = response.data.meta.total
        this.total_page = response.data.meta.last_page
        this.page = response.data.meta.current_page
        this.listLoading = false
      }).catch((error) => {
        console.log('rsp', error)
        this.listLoading = false
      })
    },

    delDataEcard (id) {
      this.listLoading = true
      delEcard(id).then((response) => {
        this.notify = {
          timeoutNotify: 6000,
          textNotify: response.data.data.message,
          titleNotify: response.data.status,
          notifyUpdate: true,
          colorNotify: 'success'
        }
        this.getListBlog()
        this.listLoading = false
      }).catch((error) => {
        console.log('rsp', error)
        this.notify = {
          timeoutNotify: 6000,
          textNotify: error.message,
          titleNotify: error.statusCode,
          notifyUpdate: true,
          colorNotify: 'error'
        }
        this.listLoading = false
      })
    },
    createDeals () {
      this.dialog = true
      this.step = 1
    },
    changeStep () {
      this.step += 1
    },
    // changeStep2 () {
    //   this.step += 1
    // },
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    },
    afterConfig (options) {
      options.series[0].areaStyle = {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      }
      options.series[1].areaStyle = {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#FFB677'
          }, {
            offset: 1,
            color: '#ffd0a8'
          }])
        }
      }
      return options
    },
    rowClick (row) {
      this.$router.push('/report/report_merchant/sales/2')
    },
    goStepTwo () {
      this.step = 2
      this.dialogWidth = '960'
    },
    dupCard () {
      if (this.cardSem.length <= 2) {
        var n = this.cardSem.length + 1
        this.cardSem.push({
          name: 'e-Card ' + n,
          idx: this.cardSem.length
        })
      }
    },
    changeCard (id) {
      this.selectCard = this.cardSem[id]
    },
    delCard () {
      this.cardSem.splice(this.selectCard.idx, 1)
      this.selectCard = this.cardSem[this.cardSem.length - 1]
    }
  }
}
</script>
