import request from '@/utils/request'
import Vuetify from '@/plugins/vuetify'
var langId = Vuetify.preset.lang.current

export function listEcard (param) {
  return request({
    url: langId + '/card',
    method: 'get',
    params: param
  })
}

export function delEcard (id) {
  return request({
    url: langId + '/card/' + id,
    method: 'delete'
  })
}
