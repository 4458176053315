<template>
    <div>
        <v-card flat>
          <div>
            <v-container class="white lighten-5 fill-height">
                <v-row class="mb-6 pl-5" no-gutters>
                <v-col :md="3">
                  <v-card @click="createDeals">
                    <v-img
                      src="@/assets/deals-bg.png"
                      class="white--text align-end"
                      height="200px">
                      <div class="ml-4" style="position: relative; top: -50px">
                        <v-row>
                          <v-col :md="5">
                            <v-avatar size="88" tile>
                              <!-- <v-img src="@/assets/cardcard.png"></v-img> -->
                              <v-icon>$vuetify.icons.hot_deals</v-icon>
                            </v-avatar>
                          </v-col>
                          <v-col :md="6" class="pt-6">
                            <span class="title font-weight-bold" style="text-align: left; color: #E74C3C;">{{ this.lang.t('$vuetify.create_newdeals') }}</span>
                          </v-col>
                        </v-row>
                        </div>
                    </v-img>
                  </v-card>
                </v-col>
                <v-col :md="4" class="ml-6">
                  <v-card class="pt-6" width="400px" height="200px" :raised="true">
                      <v-row class="mb-4 align-center" no-gutters>
                          <v-col :md="4" class="text-center d-flex-align-center">
                              <v-avatar size="68" tile>
                                <v-icon>$vuetify.icons.deal_service_class</v-icon>
                              </v-avatar>
                          </v-col>
                          <v-col >
                              <span class="headline font-weight-bold">121</span><br>
                              <span class="body-2" style="color: #BEC2C4">{{ this.lang.t('$vuetify.service_class_voucher') }}</span>
                          </v-col>
                      </v-row>
                      <v-row class="align-center pl-5" no-gutters>
                          <v-col :md="3" class="ma-2 text-center d-flex-align-center">
                              <div class="title text-center">766</div>
                              <div class="caption" style="color: #BEC2C4;">{{ this.lang.t('$vuetify.new_voucher') }}</div>
                          </v-col>
                          <v-col :md="3" class="ma-2 text-center d-flex-align-center">
                              <div class="title text-center">21</div>
                              <div class="caption" style="color: #BEC2C4;">{{ this.lang.t('$vuetify.service_voucher') }}</div>
                          </v-col>
                          <v-col :md="3" class="ma-2 text-center d-flex-align-center">
                              <div class="title text-center">766</div>
                              <div class="caption" style="color: #BEC2C4;">{{ this.lang.t('$vuetify.class_voucher') }}</div>
                          </v-col>
                      </v-row>
                  </v-card>
                </v-col>
                <v-col :md="4" class="ml-6">
                  <v-card class="pt-6" width="400px" height="200px" :raised="true">
                      <v-row class="mb-4 align-center" no-gutters>
                          <v-col :md="4" class="text-center d-flex-align-center">
                              <v-avatar size="68" tile>
                                <v-icon>$vuetify.icons.deal_voucher</v-icon>
                              </v-avatar>
                          </v-col>
                          <v-col >
                              <span class="headline font-weight-bold">21</span><br>
                              <span class="body-2" style="color: #BEC2C4">{{ this.lang.t('$vuetify.voucher_used') }}</span>
                          </v-col>
                      </v-row>
                      <v-row class="align-center pl-5" no-gutters>
                          <v-col :md="5" class="ma-2 text-center d-flex-align-center">
                              <div class="title text-center">11</div>
                              <div class="caption" style="color: #BEC2C4;">{{ this.lang.t('$vuetify.service_voucher') }}</div>
                          </v-col>
                          <v-col :md="5" class="ma-2 text-center d-flex-align-center">
                              <div class="title text-center">12</div>
                              <div class="caption" style="color: #BEC2C4;">{{ this.lang.t('$vuetify.class_voucher') }}</div>
                          </v-col>
                      </v-row>
                  </v-card>
                </v-col>
                </v-row>
                <v-card class="pa-2" width="100%">
                    <v-card-title>
                      <span style="color: #6A6A6A">12456 {{ this.lang.t('$vuetify.voucher') }}</span>
                      <v-spacer></v-spacer>
                      <v-btn rounded outlined class="text-capitalize mr-2" ><v-icon left>$vuetify.icons.download</v-icon>{{ this.lang.t('$vuetify.download') }}</v-btn>
                      <v-btn rounded outlined class="text-capitalize mr-2" ><v-icon left>$vuetify.icons.print</v-icon>{{ this.lang.t('$vuetify.print') }}</v-btn>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        style="width: 10px;"
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table
                      :headers="headers"
                      :items="desserts"
                      :search="search"
                      hide-default-footer
                      class="mytable"
                      style="border-bottom: none"
                      @click:row="rowClick"
                    >
                    <template v-slot:header.service_name="{ header }">
                      <span style="color: #00B082">{{ header.text }}</span>
                    </template>
                    <template v-slot:item.cancelled="{ item }">
                      <span style="color: #E84118">{{ item.cancelled}}</span>
                    </template>
                    <template v-slot:item.action="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)">mdi-pencil</v-icon>
                      <v-icon
                        small
                        @click="deleteItem(item)">mdi-delete</v-icon>
                    </template>
                    </v-data-table>

                    <v-spacer></v-spacer><br>
                    <v-pagination
                      v-model="page"
                      color="#00B082"
                      :length="total_page"
                      :total-visible="7"
                      circle
                      ></v-pagination>
                </v-card>
            </v-container>
            </div>
            <v-dialog
              v-model="dialog"
              max-width="664">
              <v-card class="pb-5">
                <v-toolbar color="elevation-0">
                  <v-toolbar-title >
                    <v-icon v-if="step > 1" @click="backStep">mdi-arrow-left</v-icon>
                    <span class="ml-4 font-weight-bold">{{ this.lang.t('$vuetify.create_newdeals') }}</span><br>
                    <div v-if="step === 1" class="body-2 ml-4" style="color: #BEC2C4">{{ this.lang.t('$vuetify.step_1deals') }}</div>
                    <div v-if="step === 2" class="body-2 ml-10" style="color: #BEC2C4;">{{ this.lang.t('$vuetify.step_2deals') }}</div>
                    <div v-if="step === 3" class="body-2 ml-10" style="color: #BEC2C4">{{ this.lang.t('$vuetify.step_3deals') }}</div>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn icon @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <div class="mx-6 mt-6">
                  <div v-if="step === 1" class="ml-2">
                    <span class="body-1" style="color: #BEC2C4">{{ this.lang.t('$vuetify.merchant_name') }}</span>
                    <v-row>
                      <v-col :md="8">
                        <v-text-field
                          v-model="search"
                          :label="this.lang.t('$vuetify.search') + ' ' + this.lang.t('$vuetify.city')"
                          color="#BEF4E6"
                          dense
                          single-line
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-autocomplete
                          v-model="selectCity"
                          :items="dropdown_font"
                          dense
                          solo
                          filled
                          label="All City"
                          background-color="#F5F5F5"
                          ></v-autocomplete>
                      </v-col>
                    </v-row>
                      <v-row>
                        <v-col :md="6" v-for="i in 12" :key="i">
                          <v-card @click="changeStep">
                            <div class="d-flex flex-no-wrap justify-space-between">
                              <v-card style="border-bottom-right-radius: 100px; width: 56px" >
                                <v-img
                                  src="@/assets/Zenwel_Thumbnail1-01.png"
                                  class="white--text align-end"
                                  height="100%"/>
                              </v-card>
                              <div>
                                <v-card-title class="subtitle-1">
                                  Title adadsada dadsasdsa
                                </v-card-title>
                              </div>
                            </div>
                          </v-card>
                        </v-col>
                        <!-- <v-col >
                          <div class="float-right text-left">
                            <v-btn v-for="item in btn" :key="item.id" class="mr-2 mb-4" width="136" rounded color="#00D79E" :bottom="true" outlined retain-focus-on-click @click="selectRange(item.id)">{{ item.value }}</v-btn>
                          </div>
                        </v-col> -->
                      </v-row>
                    </div>
                    <div v-if="step === 2" class="ml-2">
                    <v-row>
                      <v-col :md="8">
                        <v-text-field
                          v-model="search"
                          :label="this.lang.t('$vuetify.search') + ' ' + this.lang.t('$vuetify.service')"
                          color="#BEF4E6"
                          dense
                          single-line
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-autocomplete
                          v-model="selectService"
                          :items="dropdown_service"
                          dense
                          solo
                          filled
                          label="Service"
                          background-color="#F5F5F5"
                          ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-tabs
                      v-model="tab"
                      color="#00D79E"
                      background-color="transparent"
                      slider-size="1"
                      class="mb-4">
                      <v-tabs-slider color="#00D79E"></v-tabs-slider>

                      <v-tab v-for="item in itemsTab" :key="item">
                      {{ item }}
                      </v-tab>
                    </v-tabs>
                    <div>
                      <span class="headline font-weight-black">{{ checkbox1.length }} {{ lang.t('$vuetify.item') + ' ' + lang.t('$vuetify.selected') }}</span>
                      <v-spacer></v-spacer>
                      <span class="body-1">Hair</span>
                    </div>
                    <v-row class="mb-3">
                      <v-col :md="6" v-for="i in 5" :key="i">
                        <v-card>
                          <div class="d-flex flex-no-wrap justify-space-between ml-4">
                              <!-- <v-checkbox v-model="checkbox1" :value="i"></v-checkbox> -->
                              <v-checkbox v-model="checkbox1" :value="i"></v-checkbox>
                            <div>
                              <v-card-title class="subtitle-1">
                                <span>Haircut Short - Lesitin Treatment</span>
                              </v-card-title>
                            </div>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                    <div style="max-width: 664px; position: fixed; bottom: 32px; margin-left: -32px;">
                      <v-btn class="text-capitalize" color="#00D79E" width="664" style="color: #FFFFFF" :disabled="disableNext" @click="step += 1">{{ lang.t('$vuetify.next') }}</v-btn>
                    </div>
                    </div>
                    <div v-if="step === 3" class="ml-2">
                    <v-card raised>
                      <div class="d-flex flex-no-wrap justify-space-between ml-4">
                        <div>
                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-subtitle style="color: #BEC2C4">2 Services Selected</v-list-item-subtitle>
                              <v-list-item-title class="body-1">Haircut - Bald, Special Massage by Djemari</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                        <v-btn text class="mt-4" color="#1A73E8">{{ lang.t('$vuetify.change') }}</v-btn>
                      </div>
                    </v-card>
                      <v-row>
                        <v-col :md="6" >
                            <div class="justify-space-between">
                              <v-radio-group v-model="coloum" row style="margin-bottom: -20px">
                                <v-radio label="Percentage" value="1"></v-radio>
                                <v-radio label="Amount" value="2"></v-radio>
                              </v-radio-group>
                              <div v-if="coloum === '1'">
                                <v-text-field
                                  v-model="amount"
                                  suffix="%"
                                  outlined
                                  dense
                                  type="number"
                                ></v-text-field>
                              </div>
                              <div v-else>
                                <v-row class="mt-n8 mb-n3">
                                  <v-col :md="4">
                                    <v-autocomplete
                                      v-model="selectCity"
                                      :items="['Rp', '$']"
                                      outlined
                                      dense
                                      ></v-autocomplete>
                                  </v-col>
                                  <v-col>
                                    <v-text-field
                                      v-model="amount"
                                      outlined
                                      dense
                                      type="number"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </div>
                                <div class="d-flex flex-no-wrap justify-space-between" style="margin-bottom: -20px">
                                  <span class="mt-4">{{ lang.t('$vuetify.minimum_purchase') }}</span>
                                  <v-switch
                                    v-model="switch1"
                                  ></v-switch>
                                </div>
                              <v-text-field
                                v-model="amount"
                                outlined
                                dense
                              ></v-text-field>
                              <div class="d-flex flex-no-wrap justify-space-between" style="margin-bottom: -20px">
                                  <span class="mt-4">{{ lang.t('$vuetify.voucher_limit') }}</span>
                                  <v-switch
                                    v-model="switch1"
                                  ></v-switch>
                                </div>
                              <v-text-field
                                v-model="amount"
                                outlined
                                dense
                              ></v-text-field>
                              <div class="d-flex flex-no-wrap justify-space-between" style="margin-bottom: -20px">
                                  <span class="mt-4">{{ lang.t('$vuetify.max_usage_customer') }}</span>
                                  <v-switch
                                    v-model="switch1"
                                  ></v-switch>
                                </div>
                              <v-text-field
                                v-model="amount"
                                outlined
                                dense
                              ></v-text-field>
                              <div class="d-flex flex-no-wrap justify-space-between">
                                <span class="mt-4">{{ lang.t('$vuetify.platform_applied') }}</span>
                              </div>
                              <v-autocomplete
                                v-model="values"
                                :items="['App (Android & iOS)', 'Web']"
                                outlined
                                dense
                                multiple
                              ></v-autocomplete>
                            </div>
                        </v-col>
                        <v-col :md="6" >
                            <div class="justify-space-between">
                              <div class="d-flex flex-no-wrap justify-space-between" >
                                  <span class="mt-4 mb-2">{{ lang.t('$vuetify.expiry_period') }}</span>
                                </div>
                              <v-autocomplete
                                v-model="selectCity"
                                :items="dropdown_gender"
                                outlined
                                dense
                                label="1 Month"
                                ></v-autocomplete>
                              <div class="d-flex flex-no-wrap justify-space-between">
                                  <span class="mt-4 mb-2">{{ lang.t('$vuetify.day') }} <v-icon left>$vuetify.icons.info</v-icon></span>
                                </div>
                              <v-autocomplete
                                v-model="selectCity"
                                :items="dropdown_gender"
                                outlined
                                dense
                                label="Everyday"
                                ></v-autocomplete>
                                <div class="d-flex flex-no-wrap justify-space-between">
                                  <span class="mt-4 mb-2">{{ lang.t('$vuetify.priority_level') }} <v-icon left>$vuetify.icons.info</v-icon></span>
                                </div>
                              <v-autocomplete
                                v-model="selectCity"
                                :items="dropdown_gender"
                                outlined
                                dense
                                label="1"
                                ></v-autocomplete>
                                <div class="d-flex flex-no-wrap justify-space-between">
                                  <span class="mt-4 mb-2">{{ lang.t('$vuetify.location_applied') }} <v-icon left>$vuetify.icons.info</v-icon></span>
                                </div>
                              <v-autocomplete
                                v-model="selectCity"
                                :items="dropdown_gender"
                                outlined
                                dense
                                label="All Location"
                                ></v-autocomplete>
                            </div>
                            <div class="text-center mt-6">
                              <v-btn width="552px" height="64px" rounded color="#00D79E" style="color: #FFFFFF">{{ lang.t('$vuetify.create') }}</v-btn>
                            </div>
                        </v-col>
                      </v-row>
                    </div>
                </div>
              </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'

export default {
  name: 'ListHotDeals',
  props: ['extend', 'page', 'total_page'],

  mounted () {
    console.log('icon', this.$vuetify.icons)
  },

  data () {
    return {
      merchantList: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        }
      ],
      headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.merchant_name'),
          align: 'left',
          value: 'service_name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.item'),
          align: 'left',
          value: 'price'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.discount'),
          align: 'center',
          value: 'total_booked'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.action'),
          align: 'center',
          value: 'action'
        }
      ],
      desserts: [
        {
          company_name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          total_booked: 12,
          platform: 'POS',
          joined_time: '13.00',
          date: '21 Agustus 2019 13:00',
          type: 1,
          service_name: 'Haircut - Short',
          revenue: '1.320',
          cancelled: '2',
          price: 15.000
        },
        {
          company_name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          total_booked: 12,
          platform: 'POS, Online',
          joined_time: '13:00',
          date: '21 Agustus 2019 13:00',
          type: 2,
          service_name: 'Haircut - Short',
          revenue: '1.320.000',
          cancelled: '2',
          price: 15.000
        },
        {
          company_name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          total_booked: 12,
          platform: 'POS, Online, Marketplace',
          joined_time: '13:00',
          date: '21 Agustus 2019 13:00',
          type: 3,
          service_name: 'Haircut - Short',
          revenue: '1.320.000',
          cancelled: '2',
          price: 15.000
        },
        {
          company_name: 'Kakiku Reflexologi',
          city: 'Batam',
          total_booked: 12,
          platform: 'POS',
          joined_time: '13:00',
          date: '21 Agustus 2019 13:00',
          type: 1,
          service_name: 'Haircut - Short',
          revenue: '1.320.000',
          cancelled: '2',
          price: 15.000
        }
      ],
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu1: false,
      menu2: false,
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      dropdown_service: ['Service', 'Class'],
      selectCity: '',
      tab2: null,
      itemsTab: [
        'All', 'Message Special', 'Nail Treatment', 'Djemari Special Treatment'
      ],
      dataLine: {
        columns: ['date', 'appointment', 'cancelled'],
        rows: [{ 'date': '10', 'appointment': 10, 'cancelled': 5, 'classes': 2 },
          { 'date': '11', 'appointment': 20, 'cancelled': 20, 'classes': 6 },
          { 'date': '12', 'appointment': 17, 'cancelled': 16, 'classes': 5 },
          { 'date': '13', 'appointment': 28, 'cancelled': 24, 'classes': 10 }
        ]
      },
      dataLine2: {
        columns: ['date', 'product_sold'],
        rows: [{ 'date': '10', 'product_sold': 10, 'cancelled': 5, 'classes': 2 },
          { 'date': '11', 'product_sold': 20, 'cancelled': 20, 'classes': 6 },
          { 'date': '12', 'product_sold': 17, 'cancelled': 16, 'classes': 5 },
          { 'date': '13', 'product_sold': 28, 'cancelled': 24, 'classes': 10 }
        ]
      },
      colorsLine: ['#00D79E', '#FFB677', '#C7A8FF'],
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#E74C3C'
          }, {
            offset: 1,
            color: '#FFFFFF'
          }])
        }
      },
      xaxis: {
        type: 'category',
        boundaryGap: false
      },
      search: '',
      dataRing: {
        columns: ['date', 'sales'],
        rows: [{ 'date': '10', 'sales': 20 },
          { 'date': '11', 'sales': 45 }
        ]
      },
      ringSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        offsetY: 110,
        radius: [60, 100],
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        }
      },
      colorsRing: ['#7CFDBE', '#00B082', '#A1FFD1', '#C5DBFF'],
      title: {
        text: 125,
        subtext: 'services and \n classes',
        textStyle: {
          color: '#333333',
          fontSize: '36',
          fontStyle: 'normal'
        },
        x: 'center',
        y: '80px'
      },
      dialog: false,
      step: 0,
      checkbox1: [],
      coloum: '1'
    }
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    },

    disableNext () {
      console.log('check', this.checkbox1.length)
      if (this.checkbox1.length > 0) {
        return false
      } else {
        return true
      }
    },

    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    computedDateFormattedMomentjs1 () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date2[0] ? moment(this.date2[0]).format('D') : ''
      endDate = this.date2[1] ? moment(this.date2[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    activeFilter () {
      if (this.model == null) return undefined
      return this.filters[this.model].fn
    }
  },

  methods: {
    createDeals () {
      this.dialog = true
      this.step = 1
    },
    changeStep () {
      this.step += 1
    },
    backStep () {
      this.step -= 1
    },
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    },
    afterConfig (options) {
      options.series[0].areaStyle = {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      }
      options.series[1].areaStyle = {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#FFB677'
          }, {
            offset: 1,
            color: '#ffd0a8'
          }])
        }
      }
      return options
    },
    rowClick (row) {
      this.$router.push('/report/report_merchant/sales/2')
    }
  }
}
</script>
