<template>
  <div>
    <v-container class="grey lighten-5 fill-height">
      <div style="background: #ffffff; box-shadow: 0px 0px 12px #0000000A; border-radius: 16px;">
        <v-tabs
            v-model="tab"
            align-with-title
            color="#00D79E"
            background-color="transparent">
            <v-tabs-slider color="#00D79E"></v-tabs-slider>

            <v-tab v-for="item in items" :key="item.id">
            {{ item.name }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <voucher ></voucher>
          </v-tab-item>
          <v-tab-item>
              <ecard ></ecard>
          </v-tab-item>
          <v-tab-item>
              <cashback ></cashback>
          </v-tab-item>
          <!-- <v-tab-item>
            <list-acqusition :extend="extend" :xaxis="xAxis" :page="page" :total_page="total_page"></list-acqusition>
          </v-tab-item> -->
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>

<script>
import Voucher from './hotdeals/index'
import Ecard from './ecard/index'
import Cashback from './cashback/index'
// import ListMerchant from './_list-merchant'
export default {
  name: 'AdminDeals',
  components: {
    Voucher,
    Ecard,
    Cashback
  },
  data () {
    return {
      tab: null,
      items: [
        {
          id: 0,
          name: 'Hot Deals'
        },
        {
          id: 1,
          name: 'e-Card'
        },
        {
          id: 2,
          name: 'Cashback'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.mytable .v-table tbody tr:not(:last-child) {
    border-bottom: none;
}
</style>
